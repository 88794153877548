import { useState } from "react"

import { useDebouncedCallback } from "use-debounce"

import { usePostIntegrationListingSearchedEvent } from "src/data/analytics/queries/integrationAnalyticsQueries"
import { filterUnlinkedIntegrationEntites } from "src/data/integrations/logic/integrations"
import { useFetchIntegrationEntities } from "src/data/integrations/queries/integrationEntityQueries"
import { useFetchIntegrationLinks } from "src/data/integrations/queries/integrationLinkQueries"
import {
  OwnerType,
  TIntegrationEntity,
  TIntegrationId,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { MSelect } from "src/ui/MSelect/MSelect"

export function IntegrationEntitySelect({
  value,
  onSelect,
  integrationId,
  label,
  integrationName,
}: {
  onSelect: (entity: TIntegrationEntity | null) => void
  value: TIntegrationEntity | null
  integrationId: TIntegrationId
  label?: string
  integrationName: string
}) {
  const { t, langKeys } = useTranslate()
  const [search, setSearch] = useState("")
  const { orgId } = useOrganization()

  const fetchIntegrationEntities = useFetchIntegrationEntities({
    integration: integrationId,
    orgId,
    ownerType: OwnerType.ORGANIZATION,
  })

  const postListingSearchedEvent = usePostIntegrationListingSearchedEvent()

  const postListingSearchEventDebounced = useDebouncedCallback(() => {
    postListingSearchedEvent.mutate({
      context: "integration_page",
      integration_name: integrationName,
    })
  }, 1500)

  function handleSearch(search: string) {
    setSearch(search)
    if (!!search) {
      postListingSearchEventDebounced()
    }
  }

  const integrationEntities = fetchIntegrationEntities.data?.entities ?? []
  const fetchIntegrationLinks = useFetchIntegrationLinks(
    {
      owner_id: orgId,
      integration: integrationId,
      owner_type: OwnerType.ORGANIZATION,
    },
    { enabled: integrationEntities.length > 0 }
  )

  const integrationLinks = fetchIntegrationLinks.data?.links ?? []

  const filteredEntities = filterUnlinkedIntegrationEntites(
    integrationEntities,
    integrationLinks
  )
    .map((e) => ({ ...e, id: e.entity_id }))
    .filter(
      (i) =>
        (i.name.toLowerCase().match(search.trim().toLowerCase())?.length ?? 0) >
        0
    )

  return (
    <MSelect
      options={filteredEntities}
      value={value}
      onSearch={handleSearch}
      onSelected={onSelect}
      loading={
        fetchIntegrationEntities.isLoading || fetchIntegrationLinks.isLoading
      }
      label={label ?? t(langKeys.integration_entity_text)}
    />
  )
}
